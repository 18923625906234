import { AUTH_PROVIDER } from '@/consts'
import { encodeStateQuery } from '@/utils/url'

const NAVER_LOGIN_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code`

/** 타입이 정의되지 않은 경우 일반 로그인 프로세스 수행
 * - repromt: 이용약관 재동의 요청
 * - reauthenticate: 재인증 요청 (회원탈퇴 페이지 내 본인인증 시 새로운 창 띄울때)
 */
type AuthType = 'reprompt' | 'reauthenticate'

export function loginWithNaver({
  redirectPath,
  authType,
}: {
  redirectPath?: string
  authType?: AuthType
}) {
  const path = redirectPath ?? location.href
  const AUTH_TYPE = authType ? `&auth_type=${authType}` : ''

  window.location.href =
    NAVER_LOGIN_URL +
    `&client_id=${
      process.env.NEXT_PUBLIC_NAVER_LOGIN_CLIENT_ID
    }&redirect_uri=${`${window.location.origin}/auth`}&state=${encodeStateQuery(
      AUTH_PROVIDER.NAVER,
      path,
    )}${AUTH_TYPE}`
}
