import { ReactNode } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import BottomSheet from '@/v1/Modal/BottomSheet'
import type { BottomSheetHeader } from '@/v1/Modal/BottomSheet/Header'

interface BottomSheetProps {
  header?: BottomSheetHeader
  content?: ReactNode
}

const BottomSheetModal = NiceModal.create(
  ({ header, content }: BottomSheetProps) => {
    const modal = useModal()

    const handleClose = () => {
      modal.hide()
    }

    return (
      <BottomSheet
        header={header}
        content={content}
        opened={modal.visible}
        onClose={handleClose}
      />
    )
  },
)

export default BottomSheetModal
