import { useCallback } from 'react'
import { use100vh } from 'react-div-100vh'

const MARGIN_TOP = 24 // 바텀시트 위의 24px 여백
const HEADER_HEIGHT = 66 // 바텀시트 large 헤더의 높이

/**
 * 바텀 시트 모달의 높이를 계산하는 커스텀 훅
 * TODO: 제거해야 함. 바텀시트 사용하는 쪽에서 높이를 설정할 수 있도록 변경 필요.
 * @returns
 */
const useBottomSheetHeight = () => {
  const getHeight = use100vh()

  /**
   * 바텀시트 모달의 높이를 계산하는 함수
   * @param headerHeight 바텀시트 헤더의 높이
   * @returns 바텀시트 모달의 헤더를 제외한 높이
   */
  const getBottomSheetHeight = useCallback(
    (headerHeight = HEADER_HEIGHT) => {
      return getHeight
        ? getHeight - MARGIN_TOP - headerHeight
        : `calc(100vh - ${MARGIN_TOP}px - ${headerHeight}px)`
    },
    [getHeight],
  )
  return { getBottomSheetHeight }
}

export default useBottomSheetHeight
