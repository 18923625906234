import useFeatureFlag from '@/hooks/useFeatureFlag'

/**
 * 애플 로그인 버튼 노출 플래그를 가져오는 훅
 * 현재 피처플래그는 APP_INSTALL_BANNER만 존재. APP_INSTALL_BANNER가 true일 경우 애플 로그인 버튼을 노출한다.
 * APP_INSTALL_BANNER 이름으로 혼란스러울 수 있으니, 이 커스텀 hook에 로직을 숨긴다.
 * TODO: iOS 앱이 스토어에 게시되면 일괄 삭제한다.
 */
const useAppleLoginButtonFlag = () => {
  const featureFlag = useFeatureFlag<{ APP_INSTALL_BANNER: boolean }>()
  return featureFlag?.APP_INSTALL_BANNER ?? false
}

export default useAppleLoginButtonFlag
