import { ReactNode } from 'react'
import useBottomSheetHeight from '@/hooks/useModal/useBottomSheetHeight'
import useToggleBodyScroll from '@/hooks/useToggleBodyScroll'
import Header, { BottomSheetHeader } from '@/v1/Modal/BottomSheet/Header'
import Modal from '@/v1/Modal/index'
import Overlay from '../Overlay'
import { bottomSheetStyle } from '../styles'

interface Props {
  header?: BottomSheetHeader
  content?: ReactNode
  onClose: () => void
  opened: boolean
}

const BottomSheet = ({
  header: headerProps,
  content,
  onClose,
  opened,
}: Props) => {
  useToggleBodyScroll(opened)

  if (!opened) return null

  return (
    <Modal>
      <BottomSheetInner
        onClose={onClose}
        header={headerProps}
        content={content}
      />
    </Modal>
  )
}

export const BottomSheetInner = ({
  header: headerProps,
  content,
  onClose,
}: Omit<Props, 'opened'>) => {
  const { getBottomSheetHeight } = useBottomSheetHeight()

  const handleClose = () => {
    headerProps?.onClose?.()
    onClose()
  }

  return (
    <>
      <Overlay closeable={true} onClose={handleClose} />
      <div
        className={bottomSheetStyle}
        role="dialog"
        aria-labelledby="bottomSheetTitle"
        style={{ maxHeight: getBottomSheetHeight(0) }}
      >
        {headerProps && (
          <Header
            id="bottomSheetTitle"
            {...headerProps}
            onClose={handleClose}
          />
        )}
        <div
          className="overflow-y-auto mx-auto px-md w-full"
          style={{
            // undefined일 경우, 기본값으로 설정된다.
            maxHeight: getBottomSheetHeight(!headerProps ? 0 : undefined),
          }}
        >
          {content}
        </div>
      </div>
    </>
  )
}

export default BottomSheet
