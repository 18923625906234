import { ReactElement } from 'react'
import clsx from 'clsx'
import AppleLoginButton from '@/components/AppleLoginButton'
import { KakaoLoginButton, NaverLoginButton } from '@/components/AuthButton'
import useSocialLogin from '@/hooks/useSocialLogin'

const LoginContent = ({
  description,
  redirectPath,
}: {
  description: ReactElement
  onClose?: () => void
  redirectPath?: string
}) => {
  const { handleKakaoLogin, handleNaverLogin } = useSocialLogin()

  return (
    <div
      className={clsx(
        'flex',
        'flex-col',
        'pb-md',
        'pt-3',
        'text-center',
        'mx-auto',
        'max-w-[432px]',
      )}
    >
      {description}
      <div className="flex flex-col gap-3">
        <KakaoLoginButton
          data-ga="signup_modal_kakao"
          size="md"
          text="카카오로 시작하기"
          onClick={() => handleKakaoLogin({ redirectPath })}
        />
        <NaverLoginButton
          data-ga="signup_modal_naver"
          size="md"
          text="네이버로 시작하기"
          onClick={() => handleNaverLogin({ redirectPath })}
        />
        <AppleLoginButton
          data-ga="signup_modal_apple"
          size="md"
          text="Apple로 시작하기"
        />
      </div>
    </div>
  )
}

export default LoginContent
