import { createPortal } from 'react-dom'

const Portal = ({
  children,
  selector,
}: {
  children: React.ReactNode
  selector: string
}) => {
  const element =
    typeof window !== 'undefined' && document.querySelector(selector)
  return element && children ? createPortal(<>{children}</>, element) : null
}

export default Portal
